.Modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.5);
  opacity: 1;
	animation-name: modalFadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
	}
	100% {
    opacity: 1;
	}
}

.Modal.fade-out {
  animation-name: modalFadeOut;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: .25s;
}

@keyframes modalFadeOut {
  0% {
    opacity: 1;
	}
	100% {
    opacity: 0;
	}
}

.container {
  margin: 8rem auto;
  max-width: 700px;
  box-sizing: border-box;
  background-color: #fff;	
  animation-name: slide-in;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

@keyframes slide-in {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0%);;
	}
}

.container.fade-out {
  animation-name: slide-out;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

@keyframes slide-out {
	0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(100%);;
	}
}


.container *, *:before, *:after {
  box-sizing: border-box;
}

@media all and (max-width:525px) {
  .container {
    max-width: 92%;
    margin: 2rem auto;
  }
}