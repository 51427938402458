.NavWrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Nav {
  top: 0px;
  left: 0px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
}

.Nav__black {
  z-index: 2;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
}
.Nav__in {
  animation-name: nav-slide-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .4s;
}

@keyframes nav-slide-in {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);;
	}
}

.Nav__out {
  animation-name: nav-slide-out;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .4s;
}


@keyframes nav-slide-out {
	0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(-100%);;
	}
}

@media all and (max-width:415px) {
  .Nav__black {
    font-size: 1.1em;
    height: 60px;
    line-height: 60px;
  }
}
