.header {
  font-size: 3em;
  text-transform: uppercase;
  text-align: center;
  padding-top:1em;
  padding-bottom: 0em;
}

.occupation-wrapper {
  display: flex;
  justify-content: center;
}

.occupation {
  margin-top: 0em;
}

.occupation-mobile {
  display: none;
}

.albums {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 80px;
}

.contact {
  color: #494949;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  background: #ffffff;
  padding: 20px;
  border: 1.5px solid #494949;
  display: inline-block;
  transition: all 0.4s ease 0s;
  border-radius: 2px
}

.contact:hover {
  color: #ffffff;
  background: black;
  border-color: black;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}

.design {
  font-size: 1.05em;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 4em;
}

.spinner {
  padding-bottom: 3em;
}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 2.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@media all and (max-width:525px) {

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .header {
    font-family: 'Roboto', sans-serif !important; 
    padding-top: 1.5em;
    padding-bottom: 5px;
    font-size: 2.3em;
  }
  
  .occupation-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    order: 1;
  }

  .occupation {
    display: none;
  }

  .occupation-mobile {
    display: block;
    font-size: .5em;
    margin-top: -2em;
  }
  
  .name {
    font-size: .9em;
    margin-bottom: 2em;
  }

  .albums {
    order: 4;
  }

  .contact-wrapper {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .contact {
    order: 3;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1em
  }

  .title {
    padding-right: 25px;
    padding-left: 25px;
    text-align: center;
  }

  .design {
    order: 5
  }
  
  .spinner {
    padding-bottom: 3em;
    padding-top: 2em;
  }
}